<template>
	<section class="white-bg jmm-header row top-xs start-xs">
		<div class="col-xs-12">
		</div>
	</section>
</template>

<script>
	export default {
		name: "headerInc"
	};
</script>

<style>
	.jmm-header {
		margin-bottom:20px;
		height:15px;
		display:block;	
	}
</style>

<template>
	<section class="jmm-home-container">
		<div class="row top-xs start-lg">
			<div class="col-xs-12 col-lg-12">
				<div class="row top-xs start-lg">
					<div class="col-xs-12 col-sm-12 ">
						<h1 class="white-text">Hello,<br/> I'm Jacob.</h1>
					</div> 
				</div>
			</div>
			<div class="col-xs-12 col-lg-12">
				<div class="row top-xs start-lg">
					<div class="col-xs-12 col-sm-8">
						<p class="white-text">I'm a <a class="mint-text" href="/docs/jmallach-fed-resume.pdf" rel="noreferrer nofollow" target="_blank">Front End Web Developer</a> with a passion for learning. Find me on <a class="mint-text" rel="noreferrer nofollow" target="_blank" href="https://github.com/jicub">github</a>. I wear lots of hats. I'm a dad, <a class="mint-text" rel="noreferrer nofollow" target="_blank" href="https://highlonesomepencils.com/">graphic artist</a> and <a class="mint-text" rel="noreferrer nofollow" target="_blank" href="https://www.instagram.com/barredowlstringband/">music maker</a>. Ring my electronic bell at <span class="mint-text">illo[at]jacobmallach.com</span>.</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	
</script>

<style scoped>
	h1 {
		font-size: 6rem;
		font-weight:bold;
		width:100%;

	}

	p {
		font-weight:bold;
		font-size: 2.4rem;
	}
	a:hover {text-decoration:none;}

	a {
		text-decoration:underline;
	}
	.jmm-home-container {
		padding:0 5% 0 5%;
		margin:auto;
		text-align:center;
		width:90%;
	}
</style>
import { createWebHashHistory, createRouter } from 'vue-router'
import Home from '../components/home'


const routes = [
    {
        path: "/",
        component: Home,
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
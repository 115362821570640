<template>
  <div>
    <headerInc/>
    <router-view></router-view>
    <footerInc />
  </div>
</template>

<script>
	import headerInc from "./components/header.vue";
	import footerInc from "./components/footer.vue";
	export default {
		name: "App",
		components: {
			headerInc,
			footerInc
		}
	};
</script>
<template>
	<section class="white-bg jmm-footer row top-xs start-xs">
		<div class="col-xs-12">
			
		</div>
	</section>
</template>

<script>
	export default {
		name: "footerInc"
	};
</script>
<style>
	.jmm-footer {
		margin-top:25px;
		height:3px;
		width:100%;
	}
</style>